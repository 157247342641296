// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://b6ca30f52c4c4ac299cbb0235418256c@o656834.ingest.sentry.io/5763057',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['dev.api.assembly.simonsays.ai', 'api.assembly.simonsays.ai', /^\//],
    }),
  ],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
